@font-face {
    font-family: 'Montserrat-Bold';
    src: url('./assets/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-Extralight';
    src: url('./assets/Montserrat-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-Medium';
    src: url('./assets/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-Semibold';
    src: url('./assets/Montserrat-SemiBold.ttf') format('truetype');
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    background-image: url('./assets/starry-sky.jpg');
    background-size: cover;
    color: white;
    padding: 0 50px;
}

.content {
    max-width: 50%;
}

.date-location {
    font-weight: bold;
    font-family: 'Montserrat-Medium';
}

.title {
    font-weight: bold;
    margin: 20px 0;
    font-family: 'Montserrat-Bold';
    color: #E9EBEE;
}


.blinking-cursor {
    animation: blink 1s infinite;
    font-weight: 900;
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}

.subtitle {
    margin-bottom: 40px;
    font-family: 'Montserrat-Extralight';
    color: #D7D9DD;
}

.coming-soon {
    font-family: 'Montserrat-Semibold';
    color: #B9BBC1;
}

.moon-image {
    max-width: 40%;
}

.moon-image img {
    width: 100%;
    height: auto;
}

@media (max-height: 586px) {
    .date-location {
        font-size: 35px;
    }

    .title {
        font-size: 100px;
    }

    .blinking-cursor{
        font-size: 100px;
    }

    .subtitle {
        font-size: 35px;
    }

    .coming-soon {
        font-size: 25px;
    }
}

@media (min-height: 586px) {
    .date-location {
        font-size: 4rem;
    }

    .title {
        font-size: 8rem;
    }

    .blinking-cursor{
        font-size: 8rem;
    }

    .subtitle {
        font-size: 3rem;
    }

    .coming-soon {
        font-size: 2.5rem;
    }
}

@media (max-width: 576px) {
    .date-location {
        font-size: 2rem;
    }

    .title {
        font-size: 3rem;
    }

    .blinking-cursor {
        font-size: 3rem;
    }

    .subtitle {
        font-size: 1.5rem;
    }

    .coming-soon {
        font-size: 1rem;
    }

    .moon-image {
        max-width: 80%;
    }

    .content {
        max-width: 80%;
    }

    .container {
        padding: 0 15px;
    }
}
